import React from 'react';
import styled from 'styled-components';
import { Row, Container, Col } from "react-bootstrap";
import { Link } from "gatsby";

const HeroWithBg = styled(Container)`
  position: relative;
  z-index: 1;
  padding: 100px 0 70px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 60px;
  max-height: 290px;
  color: white;
  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.75;
    background-color: #000000;
  }
`;

export const HeroSection = ({ children }) => {
  return (
    <HeroWithBg fluid className="hero-area-objekti">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center font-bold">{children}</h1>
          </Col>
        </Row>
        <div className="text-center">
          <Link to="/" className="link-no-style">Početna</Link>
          <span className="mx-2">-></span>
          {children}
        </div>
      </Container>
    </HeroWithBg>
  );
};
