import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import styled from 'styled-components';

import { HeroSection } from '../components/common/HeroWithBg';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosPin, IoIosMail } from "react-icons/io";


const OverlaySubscribe = styled(Container)`
background-color: rgba(250, 188, 61, 0.9);
  padding: 0px;
  margin: 0px;
`;

const Forma = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 320px;
  }
`;

const Kontakt = () => {

  return (
    <Layout pageInfo={{ pageName: "Kontakt" }}>
      <SEO title="Kontakt" keywords={[`art`, `arhitektura`, `objekti`]} />

      <HeroSection>
        Kontakt
      </HeroSection>

      <Container className="mt-2">
        <h2 className="mb-4">Kontakt Informacije</h2>
        <Row>

          <Col xs={12} md={4} className="d-flex">
            <IoIosPin size="50px" />
            <p className="display-5">
              NZ doo<br />
              Izvorska 48,<br />
              11000 Beograd,<br />
              Srbija
            </p>
          </Col>

          <Col xs={12} md={4} className="d-flex">
            <FaPhoneAlt size="40px" className="mr-3" />
            <p className="display-5">
              Nada Jovičić Ivančajić<br />
              Zlatan Vukosvaljević<br />
              +381 63 252-069
            </p>
          </Col>

          <Col xs={12} md={4} className="d-flex">
            <IoIosMail size="50px" className="mr-3" />
            <p className="display-5">
              https://nzbeograd.com<br />
              nz.house@yahoo.com<br />
              nznadaarh@gmail.com<br />
            </p>
          </Col>

        </Row>
      </Container>

      <Container className="mt-2">
        <iframe title="mapa-firme" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3698.7912382300287!2d20.416841265020416!3d44.77148971425123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6e2afc96a8b5%3A0xecf3e95cd3f84266!2sIzvorska%2048%2C%20Beograd%2C%20Serbia!5e0!3m2!1sen!2sus!4v1591931138365!5m2!1sen!2sus" width="100%" height="450" frameborder="0" allowfullscreen="" aria-hidden="false"></iframe>
      </Container>


      <Container fluid className="subscribe-section no-p-m">
        <OverlaySubscribe fluid className="no-p-m text-center pt-5 pb-5 d-flex justify-content-center">
          {/* <Container fluid className="text-center "> */}
          <Row>
            <Col xs={12} md={4}>
              <h2 className="display-5 mb-2">Prijavite se na našoj listi</h2>
            </Col>
            <Col xs={12} md={8} className="d-flex">
              <form className="form-inline w-100" name="subskrajberi" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                <p className="hidden sr-only">
                  <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                </p>
                <Forma className="form-group">
                  <label htmlFor="emailSubscription" className="sr-only">Email</label>
                  <input type="email" className="form-control form-control-lg w-100" id="emailSubscription" placeholder="Vaša email adresa" />
                </Forma>
                <button type="submit" className="btn btn-outline-secondary btn-lg">Prijavite se</button>
              </form>
            </Col>
          </Row>
          {/* </Container> */}
        </OverlaySubscribe>
      </Container>

    </Layout >
  );
}

export default Kontakt;

